@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    /* font-size: 12px; */
  }

  ::selection {
    @apply bg-primary-200 text-primary-800;
  }

  input:disabled {
    @apply bg-trueGray-200;
  }

  .os-host .os-scrollbar {
    @apply opacity-50 transition;
  }

  .os-host:hover .os-scrollbar {
    @apply opacity-100;
  }
}

@layer components {
}